import 'script-loader!jquery';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import Rails from '@rails/ujs';
Rails.start();

import '../src/user_time_zone.js';
import '../src/hcaptcha.js';
import '../src/vue_mount';
import '../src/otp_profile.js';
import '../src/click_outside.js';

import Cookie from "../src/cookie.js";
window.Cookie = Cookie;

import '../src/sentry.js';
